<template>
  <div>
    <!-- Show the main dialog -->
    <v-dialog
      :value="value"
      @input="$emit('input', $event)"
      :persistent="isMakingNetworkRequest"
      :loading="isMakingNetworkRequest"
      max-width="900"
      scrollable
    >
      <v-card>
        <v-card-title class="primary white--text pb-4 d-flex justify-space-between">
          <span>
            Influencer Submissions
          </span>

          <v-btn
            icon
            color="white"
            @click="$emit('input', false)"
          >
            <v-icon>
              close
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-4">
          <v-card class="box-shadow-soft rounded rounded-bl-0 rounded-br-0 px-3 py-6">
            <v-row>
              <v-col
                cols="6"
                md="3"
              >
                <platform-selector
                  v-model="query.platform"
                  label="Platform"
                  small
                  show-all
                  outlined
                  full-width
                  @change="fetchInfluencers"
                />
              </v-col>

              <v-col
                cols="6"
                md="3"
              >
                <v-select
                  v-model="query.is_approved"
                  label="Filter"
                  outlined
                  dense
                  hide-details
                  :items="approvalStatusOptions"
                  @change="fetchInfluencers"
                ></v-select>
              </v-col>

              <v-col
                v-if="selectedItems.length"
                cols="12"
                md="6"
              >
                <div class="d-flex justify-end">
                  <v-btn
                    depressed
                    color="primary"
                    @click="shouldShowApprovalDialog = true"
                    :disabled="isMakingApprovalRequest"
                  >
                    <v-icon left>
                      done
                    </v-icon>

                    Approve
                  </v-btn>

                  <v-btn
                    depressed
                    class="ml-3"
                    color="primary"
                    @click="shouldShowDeleteDialog = true"
                    :disabled="isMakingDeleteRequest"
                  >
                    <v-icon left>
                      delete
                    </v-icon>

                    Delete
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card>

          <!-- Show the results table -->
          <v-data-table
            v-model="selectedItems"
            :headers="tableHeaders"
            :items="response.data"
            :server-items-length="response.total"
            :page="query.page"
            :items-per-page="query.perPage"
            @update:page="(value) => { query.page = value; fetchInfluencers(); }"
            @update:items-per-page="(value) => { query.perPage = value; fetchInfluencers(); }"
            show-select
            mobile-breakpoint="100"
            class="box-shadow-soft"
          >
            <!-- For Platform -->
            <template v-slot:item.platform="{ item }">
              <div class="d-flex">
                <v-img height="30" width="30" max-width="30" :src="platformIconMap[item.platform]" />
              </div>
            </template>

            <!-- Modify the influencer column -->
            <template v-slot:item.username="{ item }">
              <profile-chip
                :platform="item.platform"
                :data="item.preview"
              />
            </template>

            <!-- Modify the followers column -->
            <template v-slot:item.followers="{ item }">
              {{ item.preview && item.preview.followers ? nFormatter(item.preview.followers) : "NA" }}
            </template>

            <!-- Show the buttons -->
            <template v-slot:item.actions="{ item }">
              <div>
                <v-btn
                  small
                  depressed
                  class="mr-2"
                  color="primary"
                  @click="showSubmissionDetails(item)"
                >
                  View
                  <v-icon right>
                    fullscreen
                  </v-icon>
                </v-btn>

                <v-btn
                  small
                  depressed
                  width=115
                  color="primary"
                  :disabled="item.is_approved"
                  @click="markAsApproved(item)"
                >
                  <v-icon left>
                    done
                  </v-icon>

                  {{ item.is_approved ? "Approved" : "Approve" }}
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Show the details dialog -->
    <v-dialog
      v-model="shouldShowDetailsDialog"
      max-width="600"
      scrollable
    >
      <v-card
        v-if="selectedItem"
        flat
      >
        <v-card-title class="primary white--text">
          Influencer Details
        </v-card-title>

        <v-card-text class="pt-6 text--text">
          <!-- Show a heading -->
          <div
            class="text-body-1 font-weight-bold"
          >
            Basic Details
          </div>

          <!-- Show the preview chip -->
          <profile-chip
            :platform="selectedItem.platform"
            :data="selectedItem.preview"
            class="mt-4"
          />

          <v-text-field
            :value="selectedItem.first_name"
            label="First Name"
            class="mt-6"
            hide-details
            readonly
            outlined
          />

          <v-text-field
            v-if="selectedItem.last_name"
            :value="selectedItem.last_name"
            label="Last Name"
            class="mt-4"
            hide-details
            readonly
            outlined
          />

          <!-- If we have contact fields -->
          <template
            v-if="contactFields.length"
          >
            <!-- Show a divider -->
            <v-divider class="my-4" />

            <!-- Show a heading -->
            <div
              class="text-body-1 font-weight-bold"
            >
              Contact Details
            </div>

            <!-- Ask for contact inputs -->
            <div
              v-for="contact in contactFields"
              :key="'contact-' + contact.key"
              class="mt-4"
            >
              <v-text-field
                :value="contact.value"
                :label="capitalizeString(contact.key)"
                hide-details
                readonly
                outlined
              />
            </div>
          </template>

          <!-- If we have prices fields -->
          <template
            v-if="selectedItem.prices && selectedItem.prices.length"
          >
            <!-- Show a divider -->
            <v-divider class="my-4" />

            <!-- Show a heading -->
            <div
              class="text-body-1 font-weight-bold"
            >
              Price Details
            </div>

            <!-- Ask for price inputs -->
            <div
              v-for="price in selectedItem.prices"
              :key="'price-' + price.key"
              class="mt-4"
            >
              <v-text-field
                :value="price.value"
                :label="capitalizeString(price.key)"
                :prefix="selectedItem.currency ? currencySymbolMap[selectedItem.currency] : null"
                hide-details
                readonly
                outlined
              />
            </div>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Show the approve confirmation dialog -->
    <v-dialog
      v-model="shouldShowApprovalDialog"
      max-width="450"
    >
      <v-card flat>
        <v-card-title class="primary white--text">
          Approve submissions?
        </v-card-title>

        <v-card-text class="pt-6 text--text">
          Are you sure you want to approve the selected submissions? Selected influencers will be merged in your list.
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            text
            color="primary"
            @click="shouldShowApprovalDialog = false"
          >
            Cancel
          </v-btn>

          <v-btn
            depressed
            color="primary"
            @click="markAsApproved()"
            :disabled="isMakingApprovalRequest"
            :loading="isMakingApprovalRequest"
          >
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Show the delete confirmation dialog -->
    <v-dialog
      v-model="shouldShowDeleteDialog"
      max-width="450"
    >
      <v-card flat>
        <v-card-title class="primary white--text">
          Delete submissions?
        </v-card-title>

        <v-card-text class="pt-6 text--text">
          Are you sure you want to delete the selected submissions? This action cannot be undone.
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            text
            color="primary"
            @click="shouldShowDeleteDialog = false"
          >
            Cancel
          </v-btn>

          <v-btn
            depressed
            color="primary"
            @click="deleteSubmissions"
            :disabled="isMakingDeleteRequest"
            :loading="isMakingDeleteRequest"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Import children components
const PlatformSelector = () => import(/* webpackChunkName: "platform-selector" */ "@/blocks/common/selectors/PlatformSelector")
const ProfileChip = () => import(/* webpackChunkName: "profile-chip" */ "@/blocks/common/ProfileChip")
const StatusChip = () => import(/* webpackChunkName: "status-chip" */ "@/blocks/common/StatusChip")

// Import helper data
import currencySymbolMap from "@/helpers/currencySymbolMap"

// Export the SFC
export default {
  // Name of the component
  name: "InviteFormInfluencers",

  // Register the child components
  components: {
    PlatformSelector,
    ProfileChip,
    StatusChip
  },

  // Accept incoming data from parent
  props: {
    value: Boolean,
    model: {
      type: Object,
      required: false,
    }
  },

  // Define local data variables
  data: () => ({
    isMakingApprovalRequest: false,
    isMakingNetworkRequest: false,
    isMakingDeleteRequest: false,

    selectedItem: null,
    selectedItems: [],
    shouldShowDeleteDialog: false,
    shouldShowApprovalDialog: false,
    shouldShowDetailsDialog: false,

    // Store the filters
    query: {
      page: 1,
      perPage: 10,

      search: "",
      platform: null,
      is_approved: null
    },

    approvalStatusOptions: [
      {
        text: "All",
        value: null
      },
      {
        text: "Approved",
        value: "true"
      },
      {
        text: "Unapproved",
        value: "false"
      },
    ],

    response: {
      total: 0,
      data: []
    },

    tableHeaders: [
      {
        text: "",
        value: "platform",
      },
      {
        text: "Influencer",
        value: "username",
      },
      {
        text: "Followers",
        value: "followers",
      },
      {
        text: "Actions",
        value: "actions",
        align: "end"
      }
    ],

    currencySymbolMap
  }),

  // Define computable properties
  computed: {
    /**
     * Get the contact fields for selected item
     *
     * @returns {Array}
     */
    contactFields() {
      // If the selected item is null
      if (!this.selectedItem) {
        return []
      }

      // Otherwise
      const items = []

      // Loop through and add the values
      this.selectedItem.phone && items.push({ key: "phone", value: this.selectedItem.phone })
      this.selectedItem.email && items.push({ key: "email", value: this.selectedItem.email })
      this.selectedItem.address && items.push({ key: "address", value: this.selectedItem.address })

      // Return the items
      return items
    }
  },

  // Define local method functions
  methods: {
    /**
     * Fetch the influencers
     *
     * @returns {void}
     */
    async fetchInfluencers() {
      // If it's already making a request
      if (this.isMakingNetworkRequest) {
        return
      }

      // Set the loading state
      this.isMakingNetworkRequest = true

      // Fetch the influencers
      try {
        // Compute query parameters
        const queryParams = new window.URLSearchParams(Object.entries(this.query).filter(([key, value]) => value))

        // Make the network request
        const response = await axios({
          url: `/api/invite-forms/${this.model.id}/submissions?${queryParams}`,
        })

        // Update the response data
        this.response = response.data
      }
      // Catch any error
      catch (error) {
        logger({ error, type: "InviteForm/Influencers Fetch" })

        // Show a message
        this.$store.dispatch("toasts/add", { text: "Failed to fetch the list, please try again!"})
      }
      // Nonetheless
      finally {
        // Reset the loading state
        this.isMakingNetworkRequest = false
      }
    },

    /**
     * Show the submission details
     *
     * @param {Object} item
     * @returns {void}
     */
    showSubmissionDetails(item) {
      // Set the selected item
      this.selectedItem = item

      // Show the dialog
      this.shouldShowDetailsDialog = true
    },

    /**
     * Mark the influencer as approved
     *
     * @param {Object} item
     * @returns {void}
     */
    async markAsApproved(item = null) {
      // If it's already making a request
      if (this.isMakingApprovalRequest) {
        return
      }

      // Compute the items
      const items = item ? [item] : this.selectedItems

      // If there are no items
      if (!items.length) {
        return
      }

      // Set the loading state
      this.isMakingApprovalRequest = true

      // Make the network request
      try {
        // Make the network request
        const response = await axios({
          method: "PUT",
          url: `/api/invite-forms/${this.model.id}/submissions`,
          data: {
            ids: items.map((iterator) => iterator.id),
          }
        })

        // Reset the loading state
        this.isMakingApprovalRequest = false
        this.shouldShowApprovalDialog = false

        // Reset the selected items
        this.selectedItems = []

        // Refresh the influencers
        this.fetchInfluencers()

        // Also emit an event
        this.$emit("refresh", {
          unapproved_influencers_count: response.data.unapproved_influencers_count || 0
        })
      }
      // Catch any error
      catch (error) {
        logger({ error, type: "InviteForm/Influencers markAsApproved" })

        // Show a message
        this.$store.dispatch("toasts/add", { text: "Failed to approve the influencer, please try again!"})

        // Reset the loading state
        this.isMakingApprovalRequest = false
      }
    },

    /**
     * Delete the selected submissions
     *
     * @returns {void}
     */
    async deleteSubmissions() {
      // If it's already making a request
      if (this.isMakingDeleteRequest) {
        return
      }

      // If there are no items
      if (!this.selectedItems.length) {
        return
      }

      // Set the loading state
      this.isMakingDeleteRequest = true

      // Make the network request
      try {
        // Make the network request
        const response = await axios({
          method: "DELETE",
          url: `/api/invite-forms/${this.model.id}/submissions`,
          data: {
            ids: this.selectedItems.map((iterator) => iterator.id),
          }
        })

        // Reset the loading state
        this.isMakingDeleteRequest = false
        this.shouldShowDeleteDialog = false

        // Reset the selected items
        this.selectedItems = []

        // Refresh the influencers
        this.fetchInfluencers()

        // Also emit an event
        this.$emit("refresh", {
          influencers_count: response.data.influencers_count || 0,
          unapproved_influencers_count: response.data.unapproved_influencers_count || 0
        })
      }
      // Catch any error
      catch (error) {
        logger({ error, type: "InviteForm/Influencers deleteSubmissions" })

        // Show a message
        this.$store.dispatch("toasts/add", { text: "Failed to delete, please try again!"})

        // Reset the loading state
        this.isMakingDeleteRequest = false
      }
    }
  },

  /**
   * As soon as the component is created
   *
   * @returns {void}
   */
  created() {
    // If we have the model
    if (this.model) {
      // Fetch the influencers
      this.fetchInfluencers()
    }
  }
}
</script>
